import { CoreFiltersType } from '@/components/analyst/core-filters-read-only';
import { type Index } from '@/queries/graphql-types';
import { IndexCardType } from '@/queries/index-card';
import { SnapshotForHoldingsType } from '@/queries/snapshot';
import { InstrumentTypeValue } from '@/types/instrument';

export type AnalysisFiguresType = {
    sharpeRatio: Optional<string>;
    annualRollingVolatility: string;
    stdDev: string;
    drawdown: string;
    priceToEarnings: string;
    priceToSales: string;
    priceToBook: string;
};

export type FundamentalsResultType = {
    businessDescription?: string;
    grossIncomeMargin?: string;
    marketCapitalizationUsd?: string;
    oneYearAnnualRevenueGrowthRate?: string;
    enterpriseValueRevenueRatio?: string;
    netRevenueRetention?: string;
    ebitdaMargin?: string;
};

// TODO: declare next to query
export type InstrumentSearchResultType = {
    symbol: string;
    id: string;
    companyName: string;
    instrumentType: Optional<InstrumentTypeValue>;
    name: string;
    latestFundamentals: Optional<FundamentalsResultType>;
    similarInstruments?: Array<InstrumentSearchResultType>;
    exchange: string;
};

export type ProcessedIndexItem = {
    asOfDate: string;
    symbolValues: {
        [key: string]: number;
    };
    isBackcalculated?: boolean;
};

export type ProcessedAssetItem = {
    companyName: string;
    symbol: string;
    exchange: string;
    weight: number | null;
    isCash: boolean | null;
    fundamentals: {
        // TODO: enumerate the valid `key`s?
        [key: string]: string | number | null;
    };
    instrumentType: Optional<InstrumentTypeValue>;
    sector: Optional<string>;
    industry: Optional<string>;
    isShort: boolean | null;
};

export enum WeightingStrategyType {
    custom = 'custom',
    equal = 'equal',
    marketCap = 'marketCap',
    rootMarketCap = 'rootMarketCap',
    price = 'price',
    rulesBasedEqualWeighted = 'rulesBasedEqualWeighted',
}

type StrategyCommon = {
    name: string;
    id: number;
};

// A "Basic" strategy includes an explicitly declared instrument list
export type BasicStrategyType = StrategyCommon & { securityIds: Array<string> } & (
        | {
              weightingType:
                  | WeightingStrategyType.equal
                  | WeightingStrategyType.marketCap
                  | WeightingStrategyType.rootMarketCap
                  | WeightingStrategyType.price;
              weightValues?: never;
          }
        | {
              weightingType: WeightingStrategyType.custom;
              weightValues: { [key: string]: number };
          }
    );

export type RulesBasedStrategyType = StrategyCommon & {
    weightingType: WeightingStrategyType.rulesBasedEqualWeighted;
    securityIds?: never;
    weightValues?: never;
    userQuery: string;
    coreFilters: CoreFiltersType;
};

export type StrategyType = BasicStrategyType | RulesBasedStrategyType;

export const isBasicStrategy = ({ weightingType }: StrategyType): boolean => {
    return [
        WeightingStrategyType.equal,
        WeightingStrategyType.marketCap,
        WeightingStrategyType.rootMarketCap,
        WeightingStrategyType.price,
        WeightingStrategyType.custom,
    ].includes(weightingType);
};

export const asBasicStrategy = (strategy: StrategyType): BasicStrategyType | null => {
    return isBasicStrategy(strategy) ? (strategy as BasicStrategyType) : null;
};

export const isRulesBasedStrategy = ({ weightingType }: StrategyType): boolean => {
    return [WeightingStrategyType.rulesBasedEqualWeighted].includes(weightingType);
};

export const asRulesBasedStrategy = (strategy: StrategyType): RulesBasedStrategyType | null => {
    return isRulesBasedStrategy(strategy) ? (strategy as RulesBasedStrategyType) : null;
};

type VersionType = {
    strategy?: {
        __typename: string;
    };
};

export type IndexType = Pick<
    Index,
    | 'id'
    | 'name'
    | 'methodologyBlogSlug'
    | 'firstEffectiveAt'
    | 'isHidden'
    | 'isLocked'
    | 'isPortfolio'
    | 'isComponentsPrivate'
    | 'canDuplicate'
    | 'dailySnapshots'
    | 'dailySnapshotsLessCosts'
    | 'benchmarkIndexes'
    | 'aboutUrl'
    | 'bannerUrl'
    | 'annualCostRate'
    | 'organization'
    | 'displayedFundamentalsColumns'
    | 'longDescription'
    | 'description'
    | 'activeVersion'
    | 'recentUpdateMessage'
> & {
    symbol: string;
    latestSnapshot: SnapshotForHoldingsType;
    activeVersion: VersionType;
    similarIndexes: Array<IndexCardType>;
};

export type UploadIndexOpengraphImageResult =
    | {
          __typename: 'Error';
          message: string;
      }
    | ({
          __typename: 'Index';
      } & IndexType);
