export const getStringForEncoding = (str: string): string => {
    return str.replace('\n', '%0A');
};

export const isURIComponentEncoded = (str: string) => {
    try {
        // Attempt to decode the string.
        // If it's not encoded, this will throw an error.
        decodeURIComponent(str);

        // Check if the decoded string contains characters commonly encoded.
        return str.includes('%') || str.includes('+');
    } catch (e) {
        return false; // The string is not encoded.
    }
};
